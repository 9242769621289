import React from 'react';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"

// import axios from 'axios'
// import { alphaencrypt } from '../../helpers/encryption';

import './careers.css';
import FooterContainer from '../footerContainer/FooterContainer';
class Careers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      FullName: null,
      Phone: null,
      Email: null,
      Position: "---",
      Resume: null,
      tempMandatory: [],
      message: "",
      openPositions: []
    };
    this.mandatoryFields = [
      "FullName",
      "Phone",
      "Email",
      "Position",
      "Resume"
    ]
  }

  componentDidMount = async () => {
    let openPositions
    await fetch("OpenPositions.json").then(
      function (res) {
        return res.json()
      }).then(function (data) {
        openPositions = data
      }).catch(
        function (err) {
          console.log(err, ' error')
        }
      )
    this.setState({ openPositions })

  }



  handleTextChange = val => event => {
    let tempMandatory = cloneDeep(this.state.tempMandatory)

    if (includes(this.mandatoryFields, val)) {
      tempMandatory = remove(tempMandatory, element => {
        return element !== val
      })
    }

    this.setState({ tempMandatory, [val]: event.target.value, message: "" })
  }

  handleResumeChange = val => event => {
    let tempMandatory = cloneDeep(this.state.tempMandatory)

    tempMandatory = remove(tempMandatory, element => {
      return element !== val
    })
    this.setState({ tempMandatory, [val]: event.target.files[0], message: "" })
  }

  validateForm = () => {
    let tempMandatory = cloneDeep(this.state.tempMandatory)
    map(this.mandatoryFields, eachElem => {

      if (((isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem)) && eachElem !== "Resume") ||
        (eachElem === "Resume" && isEmpty(this.state[eachElem]?.name)) ||
        (eachElem === "Email" && !isEmpty(this.state[eachElem]) && this.validateEmail(this.state[eachElem]) && !includes(tempMandatory, eachElem)) ||
        (eachElem === "FullName" && !isEmpty(this.state[eachElem]) && isEmpty(this.state[eachElem].trim()) && this.validateFullName(this.state[eachElem]) && !includes(tempMandatory, eachElem)))
        tempMandatory.push(eachElem)
    })

    this.setState({
      tempMandatory,
      message: !isEmpty(tempMandatory) ? "Some fields are empty or invalid" : "",
      messageColor: !isEmpty(tempMandatory) ? "danger" : ""
    })

    setTimeout(() => {
      this.setState({ message: "" })
    }, 8000)

    if (!isEmpty(tempMandatory)) return false
    else return true

  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
  }

  validateFullName = (fullName) => {
    const re = /[a-zA-Z]+$/;
    return !re.test(String(fullName).toLowerCase());
  }


  tobase64 = (file) => new Promise((rs, rj) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => rs(reader.result);
    reader.onerror = error => rj(error);
  });


  Submit = async () => {
    const { FullName, Phone, Email, Position, Resume } = this.state
    if (this.validateForm()) {

      let file = await this.tobase64(Resume)
      let ResumeSend = { filename: Resume.name, content: file, type: Resume.type }

      document.getElementById("SpinnerMaincontainer").style.display = "block"
      let params = {
        name: FullName,
        phone: Phone,
        email: Email,
        position: Position,
        attachements: JSON.stringify(ResumeSend)
        // resume: ResumeSend,
      }


      const options = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }

      fetch(`${window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/phpSendMail/php/'}helpers/phpmailer.php`, options)
        .then(res => {

          return res.text()
        })
        .then(res => {
          if (res === 'Message has been sent') {
            this.setState({
              FullName: null,
              Phone: null,
              Email: null,
              Position: "---",
              Resume: null,
              tempMandatory: [],
              message: "Message Sent successfully",
              messageColor: "success",
            })
            document.getElementById("Resume").value = "";
            document.getElementById("SpinnerMaincontainer").style.display = "none"

          }
          else
            this.setState({ message: "Error While Sending Message, Please Try Later", messageColor: "danger" })
          document.getElementById("SpinnerMaincontainer").style.display = "none"
        })
        .catch(error => {
          if (error) {
            this.setState({ message: "Error While Sending Message, Please Try Later", messageColor: "danger" })
            document.getElementById("SpinnerMaincontainer").style.display = "none"
          }
        });

      // let d1 = (new Date()).getTime().toString() + "SendMailCTSERV";
      // axios({
      //   method: "post",
      //   url: `${'http://92.62.175.99/SendMail/Service1.svc/web/'}SendMail`,
      //   data: params,
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     "authenticationInfo": alphaencrypt(d1)
      //   },
      // })
      //   .then(response => {
      //     if (response.data.SendMailResult === "OK") {
      //       this.setState({
      //         FullName: null,
      //         Phone: null,
      //         Email: null,
      //         Position: "---",
      //         Resume: null,
      //         tempMandatory: [],
      //         message: "Message Sent successfully",
      //         messageColor: "success",
      //       })
      //       document.getElementById("Resume").value = "";
      //       document.getElementById("SpinnerMaincontainer").style.display = "none"

      //     }
      //     else
      //       this.setState({ message: "Error While Sending Message, Please Try Later", messageColor: "danger" })
      //     document.getElementById("SpinnerMaincontainer").style.display = "none"
      //   })
      //   .catch(error => {
      //     if (axios.isCancel(error)) { console.log(error.message); }
      //     this.setState({ message: "Error While Sending Message, Please Try Later", messageColor: "danger" })
      //     document.getElementById("SpinnerMaincontainer").style.display = "none"
      //   });
    }
  }

  render() {
    let { FullName, Phone, Email, Position, tempMandatory, message, messageColor, openPositions } = this.state
    return (
      <div className="container" >

        {message &&
          <Alert className="alert-career" color={messageColor}>
            {message}
          </Alert>
        }

        <div id="careerSection" className="row">

          <div className="col-sm-12 col-lg-6">
            <h2>Apply Now!</h2>
            <div>
            </div>
            <p>We’re always on the lookout for new talent ready to rise to the occasion, help us grow and serve our clients.</p>
            <p>Sounds like you? Submit your application and be part of the innovation!</p>
            <br />
           <p style={{fontStyle:"italic"}}>Check out our <a href="https://www.linkedin.com/company/computer-technology-&amp;-services-c-t-serv-" target="_blank">LinkedIn</a> Profile for available positions!</p>
            {/* {!isEmpty(openPositions) && <>
              <h5>Current Open Positions:</h5>

              {map(openPositions, eachElem => {
                return <p><i className="fa fa-check" aria-hidden="true"></i>{eachElem}</p>
              })}
            </>
            } */}

            {/* <p><i className="fa fa-check" aria-hidden="true"></i>Software Security Specialist (With Experience) - Jounieh Branch</p> */}
            {/* <p><i className="fa fa-check" aria-hidden="true"></i>Software Developer (2-4 Years Experience) - Jounieh Branch</p> */}
            {/* <p><i className="fa fa-check" aria-hidden="true"></i>Web Developer - Front End (Minimum 1 Year Experience) - Jounieh Branch</p> */}
            {/* <p><i className="fa fa-check" aria-hidden="true"></i>Web Developer - Full Stack (Minimum 1 Year Experience) - Jounieh Branch</p> */}
            {/* <p><i className="fa fa-check" aria-hidden="true"></i>Customer Support (Software) - Achrafieh Branch</p> */}
          </div>
          <div className="col-sm-12 col-lg-6">
            <Form>
              <FormGroup>
                <Label className={includes(tempMandatory, "FullName") ? "AlertRed" : ""}>Full Name *</Label>
                <Input type="text" name="Full Name" id="FullName"
                  value={FullName ? FullName : ""}
                  onChange={this.handleTextChange("FullName")}
                  className={includes(tempMandatory, "FullName") ? "AlertRed" : ""}
                />
              </FormGroup>

              <FormGroup>
                <Label className={includes(tempMandatory, "Phone") ? "AlertRed" : ""}>Phone *</Label>
                <Input type="number" name="Phone" id="Phone"
                  value={Phone ? Phone : ""}
                  onChange={this.handleTextChange("Phone")}
                  className={includes(tempMandatory, "Phone") ? "AlertRed" : ""} />
              </FormGroup>

              <FormGroup>
                <Label className={includes(tempMandatory, "Email") ? "AlertRed" : ""}>Email *</Label>
                <Input type="email" name="email" id="Email"
                  value={Email ? Email : ""}
                  onChange={this.handleTextChange("Email")}
                  className={includes(tempMandatory, "Email") ? "AlertRed" : ""} />
              </FormGroup>

              <FormGroup>
                <Label>Position *</Label>
                <Input
                  type="select" name="Position" id="Position"
                  value={Position}
                  onChange={this.handleTextChange("Position")}>
                  <option value="---">---</option>
                  <option value="SoftwareSecuritySpecialist">Software Security Specialist</option>
                  <option value="SoftwareDeveloper">Software Developer</option>
                  <option value="WebDeveloper">Web Developer</option>
                  <option value="BusinessAnalyst">Business Analyst</option>
                  <option value="QualityAssurance">Quality Assurance</option>
                  <option value="SoftwareSupport">Software Support</option>
                  <option value="TechnicalSupport">Technical Support (Maintenance)</option>
                  <option value="SystemsNetworkSupport">Systems &amp; Network Support</option>
                  <option value="Accounting">Accounting</option>
                  <option value="Administrative">Administrative</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Label className={includes(tempMandatory, "Resume") ? "AlertRed" : ""}>Resume *</Label>
                <Input type="file" name="Resume" id="Resume"
                  onChange={this.handleResumeChange("Resume")}
                  className={includes(tempMandatory, "Resume") ? "AlertRed" : ""} />
              </FormGroup>

              <Button color="primary" style={{ float: 'right' }} onClick={this.Submit}>Submit</Button>{' '}
            </Form>

          </div>
        </div>
          <div id="AppFooterContainer"  style={{marginTop:"50px"}}>
          <FooterContainer />
        </div>
      </div>
    )
  }

}

export default Careers;

