import React from 'react';
import { includes, isEmpty, map } from "lodash"
import { Alert,  Collapse } from 'reactstrap';

import BigSpyNav from '../bigSpyNav/BigSpyNav';
import ContactUsModal from '../contactUsModal/ContactUsModal';
import { getCtservNetData } from '../CtservNetData';

import './companyProducts.css';
import FooterContainer from '../footerContainer/FooterContainer';
class CompanyProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      subPartOpen: ""
    };
  }

  activateSuccessMsg = (message) => {
    this.setState({ message: message })

    setTimeout(() => {
      this.setState({ message: "" })
    }, 8000)
  }
  togglepart = (value) => (event) => {
    debugger
    if (this.state.subPartOpen === value)
      this.setState({ subPartOpen: "" })
    else
      this.setState({ subPartOpen: value })
  }

  render() {

    let dataToLoad = ""
    let bigSpyNav = []
    if (includes(this.props.location.pathname, "Hospitals"))
      dataToLoad = "Hospitals"
    if (includes(this.props.location.pathname, "MedicalCenter"))
      dataToLoad = "MedicalCenter"
    if (includes(this.props.location.pathname, "SupermarketsMinimarkets"))
      dataToLoad = "SupermarketsMinimarkets"
    if (includes(this.props.location.pathname, "ChainStores"))
      dataToLoad = "ChainStores"
    if (includes(this.props.location.pathname, "PayrolHR"))
      dataToLoad = "PayrolHR"
    if (includes(this.props.location.pathname, "NuTechHardware"))
      dataToLoad = "NuTechHardware"

    let companyProductData = getCtservNetData(dataToLoad)

    map(companyProductData.data, compData => {
      let temp = {
        id: compData.id,
        iconId: "icon" + compData.id,
        icon: compData.icon,
        colored: false
      }
      bigSpyNav.push(temp)
    })

    let { message } = this.state

    return (
      <div id="companyProductsContainer" className="container" >
        {message &&
          <Alert style={{ width: "100%", position: "absolute", zIndex: "100" }} color="success">
            {message}
          </Alert>
        }
        <img id="companyProductsImg" src={!isEmpty(companyProductData) && companyProductData.imageSourse} alt="" width="100%" height="auto" />


        <div className="descriptions-main-container">
        <ContactUsModal
          type={"requestDemo"}
          activateSuccessMsg={this.activateSuccessMsg}
          bottonStyle={{ width: "fit-content", padding: "5px 15px", borderRadius: "60px", fontSize: "15px"}}
          contactUsButtonStyle={ window.innerWidth > 991?{position:"absolute" , right:"20px"}:{margin:"20px calc(50% - 85px)"}} />
          
          <div className="descriptions-section-container">

            {!isEmpty(companyProductData) &&
              map(companyProductData.data, (element, key) => {
                return (<div id={element.id} style={{ height: "100%" }} key={key}>

                  <div className="row">
                    <div className="col-12 descriptions-header-container">
                      <h1 > <img className="hospital-icons" style={{ marginRight: "10px" }} src={element.icon && element.icon} alt="" />
                        {element.title && element.title}</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="descriptions-intoduction-container">
                      <h6>{element.header && element.header}</h6>

                      {element.descriptionPara &&
                        map(element.descriptionPara, (descriptionParaElem, key) => {
                          return (
                            <p className="col-12 descriptionPara" key={key}>{descriptionParaElem}</p>

                          )
                        })}
                    </div>
                  </div>
                  <div className="row descriptions-bullet-container">
                    <div
                      onClick={this.togglepart("subpart" + key)}
                      style={{ width: "100%", margin: "10px 50%", fontSize: "30px" }}>
                      {this.state.subPartOpen === "subpart" + key ?
                        <i className="fa fa-angle-up"></i>
                        :
                        <i className="fa fa-angle-down"></i>
                      }
                    </div>
                    <Collapse isOpen={this.state.subPartOpen === "subpart" + key ? true : false} className="col-12">
                      <div className='row'>
                        <div className="col-sm-12 col-lg-4">
                          {element.firstSection &&
                            map(element.firstSection, (firstSectionElem, key) => {
                              return (
                                <div key={key}>
                                  <i className="fa fa-check bulletPointsTick" aria-hidden="true"></i>
                                  <h4 className="bulletPoints">{firstSectionElem}</h4>
                                </div>
                              )
                            })}
                        </div>

                        <div className="col-sm-12 col-lg-4">
                          {element.secondSection &&
                            map(element.secondSection, (secondSectionElem, key) => {
                              return (
                                <div key={key}>
                                  <i className="fa fa-check bulletPointsTick" aria-hidden="true"></i>
                                  <h4 className="bulletPoints">{secondSectionElem}</h4>
                                </div>
                              )
                            })}
                        </div>

                        <div className="col-sm-12 col-lg-4">
                          {element.thirdSection &&
                            map(element.thirdSection, (thirdSectionElem, key) => {
                              return (
                                <div key={key}>
                                  <i className="fa fa-check bulletPointsTick" aria-hidden="true"></i>
                                  <h4 className="bulletPoints">{thirdSectionElem}</h4>
                                </div>
                              )
                            })}
                        </div>
                      </div>

                    </Collapse>
                  </div>
                </div>
                )
              })

            }

          </div>
        </div>
        <div id="AppFooterContainer" style={{margin:"0"}}>
          <FooterContainer />
        </div>
        <BigSpyNav
          data={bigSpyNav} />
      </div>
    )

  }



}
export default CompanyProducts;

